
<template>
  <div class="container mx-auto">
    <div class="terms">
      <strong>Cookie- og privatlivspolitik</strong>
      <p>
        Når du handler Zalando gavekort, kan du være helt tryg ved at bruge
        vores hjemmeside og services, og vi indsamler ikke personoplysninger,
        uden du selv har givet os disse.
      </p>
      <p>
        Vi håndterer dine data med respekt for dit privatliv, og er vores ansvar
        bevidst når vi behandler de oplysninger, du giver os - oplysninger som
        vi, naturligvis, behandler og opbevarer efter den til enhver tid
        gældende lovgivning
      </p>
      <p>Vi anbefaler, at du læser vores persondatapolitik herunder.</p>
      <p>
        Når du klikker dig ind på Zalando gavekort hjemmesiden, indsamler
        Loyalty Key ApS oplysninger om dig. Nedenfor kan du læse om de
        informationer, der indsamles, herunder hvordan oplysningerne behandles,
        hvad de bruges til, hvem der har adgang til oplysningerne, og hvem du
        kan kontakte, hvis du har spørgsmål eller indsigelser vedrørende de
        indsamlede oplysninger.
      </p>
      <strong>Dataansvarlig og kontaktoplysninger</strong>
      <p class="text-center">
        Loyalty Key ApS
        <br />
        Vester Voldgade 83, 2.
        <br />
        DK-1552 København K
        <br />
        E-mail: Info@LoyaltyKey .com
        <br />
        CVR 33 87 90 91
      </p>
      <p>
        Loyalty Key indsamler oplysninger om dig på to måder:
        <br />
        - Ved at du selv afgiver oplysninger
        <br />
        - Ved brug af cookies
      </p>
      <strong>1. Ved at du selv afgiver personoplysninger</strong>
      <p>
        Loyalty Key beder om dine personlige og brugerrelaterede oplysninger,
        når du køber tjenester på vores hjemmesider eller tilmelder dig
        nyhedsbreve, konkurrencer m.v. Vi indsamler kun identificerbare
        personoplysninger som navn, adresse, fødselsdato, telefonnummer og
        e-mail, når du selv giver dem.
      </p>
      <strong>1.1. Hvad bruger vi personoplysninger til</strong>
      <p>
        De personoplysninger vi indsamler om dig, når du fx. opretter en profil
        på Loyalty Key , bruges først og fremmest til at behandle dine
        forespørgsler og ordrer samt til at levere produkter, tjenester og
        service til dig.
        <br />
        Oplysningerne bruges derudover af Loyalty Key og samarbejdspartnere for
        at få større kendskab til dig. Denne brug kan bl.a. omfatte
        undersøgelser og analyser, der er rettet mod en forbedring af vore
        produkter, tjenester og teknologier, samt visning af indhold og
        markedsføring, der er tilpasset dine interesser.
        <br />
        Oplysningerne bliver ikke videregivet til tredjepart, medmindre du giver
        særskilt accept hertil, eller persondataloven undtagelsesvis tillader
        videregivelse af generelle kundeoplysninger.
        <br />
        Har du givet samtykke til at modtage direkte markedsføring med nyheder
        og tilbud m.v., kan vi også bruge de indsamlede oplysninger til at
        målrette den markedsføring du modtager via mail.
        <br />
        Husk at enhver oplysning du afgiver - inklusiv personlig identificerbar
        information - i et offentligt forum som for eksempel en online-debat,
        ikke er omfattet af denne fortrolighedspolitik og kan ses af tredjepart,
        der ikke har noget med Loyalty Key at gøre. Vi anbefaler derfor, at du
        omhyggeligt overvejer, hvilken information du afgiver i forbindelse med
        brug af disse.
      </p>
      <p>
        Vi lagrer ikke persondata længere end tilladt ved lov og sletter
        persondata, når de ikke længere er nødvendige til ovennævnte formål.
      </p>
      <strong>1.2. Lagring af persondata</strong>
      <p>
        Personoplysningerne lagres hos vores databehandler, som opbevarer og
        behandler persondata på vores vegne i henhold til denne
        privatlivspolitik og den gældende lovgivning om beskyttelse af
        persondata. Hvis vi videregiver dine persondata til samarbejdspartnere i
        Danmark eller i tredjelande, sikrer vi os altid, at deres niveau for
        persondatabeskyttelse passer til de krav, vi har opstillet i denne
        privatlivspolitik og som følger af gældende lovgivning.
      </p>
      <strong>1.3. Samkøring af data</strong>
      <p>
        Når du opretter en profil på Loyalty Key's hjemmeside eller giver os
        tilladelse til, at vi kan kontakte dig om nyheder og tilbud via telefon,
        brev eller elektroniske medier (e-mail, sms, mms, videobeskeder, pop-ups
        m.v.) samkører vi de oplysninger, du indtaster i din profil, med
        oplysninger indsamlet via cookies for at målrette nyheder og tilbud til
        dig. Loyalty Key benytter cookies til at indsamle oplysninger om din
        adfærd på hjemmesider og andre digitale platforme tilhørende Loyalty Key
        og samarbejdspartnere, jf. punkt 1.2. ovenfor.
      </p>
      <strong>1.4. Beskyttelse af personoplysninger</strong>
      <p>
        Ifølge persondataloven skal dine personlige oplysninger opbevares
        sikkert og fortroligt. Vi gemmer de personlige oplysninger, du afgiver,
        på computere med begrænset adgang, som er placeret i kontrollerede
        faciliteter, og vores sikkerhedsforanstaltninger kontrolleres løbende
        for at afgøre, om vores brugeroplysninger håndteres forsvarligt, og
        under stadig hensyntagen til dine rettigheder som bruger. Vi kan dog
        ikke garantere 100 % sikkerhed ved dataoverførsler via internettet. Det
        betyder, at der er en risiko for, at andre uberettiget tiltvinger sig
        adgang til oplysninger, når data sendes og opbevares elektronisk. Vær
        opmærksom på, at du afgiver dine personlige oplysninger på eget ansvar.
      </p>
      <strong>1.5. Ændringer i behandlingen af personoplysninger</strong>
      <p>
        Internettets hastige udvikling betyder, at ændringer i vores behandling
        af personoplysninger kan blive nødvendige. Vi forbeholder os derfor ret
        til at opdatere og ændre nærværende retningslinjer for behandling af
        personoplysninger. Gør vi det, retter vi selvfølgeligt datoen for
        'senest opdateret' øverst på siden. I tilfælde af væsentlige ændringer
        af denne persondatapolitik, giver vi dig besked i form af en synlig
        meddelelse på vores hjemmesider.
      </p>
      <strong>1.6. Kontakt</strong>
      <p>
        Hvis du ønsker adgang til de oplysninger, som er registreret om dig hos
        Loyalty Key , skal du rette henvendelse på info@Loyalty Key .com. Er der
        registreret forkerte data, og kan du ikke selv rette dem, kan du rette
        henvendelse samme sted. Du har mulighed for at få indsigt i, hvilke
        informationer der er registreret om dig, og du kan gøre indsigelse mod
        en registrering i henhold til reglerne i persondataloven.
      </p>
      <strong>2. Ved brug af cookies</strong>
      <p>
        Første gang du besøger Loyalty Key's hjemmesider modtager du automatisk
        en eller flere cookies. Du modtager endvidere cookies, når du foretager
        søgninger eller besøger undersider på vores hjemmesider. En cookie er en
        lille tekstfil, der lagres i din webbrowser (f.eks. Internet Explorer,
        Firefox, Chrome, Safari, Opera el.lign.), og som registrerer dig som
        unik bruger. Cookies gør det lettere at anvende internettet, fordi du
        ikke hele tiden skal begynde forfra - den pågældende hjemmeside kan med
        andre ord bruge en cookie til at huske information om dine præferencer.
      </p>
      <strong>2.1. Cookies lagring</strong>
      <p>
        Cookies er med til at fortælle Loyalty Key om dit besøg og din adfærd på
        vores hjemmesider. For at fastlægge, hvem der besøger Loyalty Key
        hjemmesider, bruger vi cookies til at føre demografiske og
        brugerrelaterede statistikker. Her indsamler vi informationer om,
        hvornår og hvor lang tid du besøger en hjemmeside, hvilke og hvor mange
        sektioner, artikler og andre produkter, du besøger, om din computer har
        besøgt os før, hvilken browser og styresystem du bruger, IP-numre,
        hvilke annoncer du ser osv. Med din tilladelse bruger vi den indsamlede
        information til at målrette vores direkte markedsføring til dig.
      </p>
      <strong>2.2. Hvor længe opbevarer vi cookies</strong>
      <p>
        De cookies, der er sendt til dig, vil blive opbevaret på dit udstyr i et
        varierende antal måneder regnet fra sidste gang du var inde på en af
        Loyalty Key's hjemmesider. Hver gang du genbesøger en hjemmeside,
        forlænges perioden. De pågældende cookies slettes automatisk efter deres
        udløb. Du kan nedenfor læse om, hvordan du kan slette cookies.
      </p>
      <strong>2.3. Hvordan undgår du cookies</strong>
      <p>
        Ønsker du ikke at acceptere cookies, kan du indstille din browser til
        automatisk at afvise cookies eller underrette dig hver gang et websted
        anmoder om at gemme cookies. Du kan altid bruge hjælpefunktionen i din
        browser for at få mere information om cookie-indstillinger. Dette gør du
        typisk ved at trykke 'F1' på din pc. Søg efter 'cookies'.
      </p>
      <strong>2.4. Cookies fra vores samarbejdspartnere</strong>
      <p>
        Loyalty Key har også en række samarbejdspartnere som sætter cookies
        -såkaldte tredjeparts-cookies. Det kan være vores leverandører,
        annoncenetværk og andre tjenester (fx Facebook), som sætter disse
        cookies. Formålet med sådanne cookies er at sikre levering af målrettede
        online-annoncer, samt gøre det muligt at logge ind og dele fx artikler
        på de pågældende samarbejdspartneres hjemmesider.
      </p>
      <strong>3. Ansvarsfraskrivelsefra vores samarbejdspartnere</strong>
      <p>
        Vi tilstræber, at indholdet af hjemmesiderne er ajour, men vi kan ikke
        garantere, at indholdet er komplet, korrekt eller udtryk for gældende
        ret. Hjemmesiderne indeholder links til andre hjemmesider tilvejebragt
        af tredjemand. Vi er ikke ansvarlige for tilgængeligheden eller
        indholdet af tredjemands hjemmesider. Vi tager forholdsregler for at
        sikre, at hjemmesiderne ikke indeholder virus, trojanske heste eller
        lignende. Vi kan imidlertid ikke garantere, at hjemmesiderne er fri for
        sådanne skadelige programmer. Vi kan ikke garantere, at de er 100 %
        beskyttet mod hacking eller lignende.
      </p>
    </div>
  </div>
</template>

<script>
import Layout from "@/Shared/Layout";

export default {
  name: "TermsPrivacy",
  layout: Layout,
};
</script>
